import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../utils/apk";
import { toast } from "react-toastify";
import { handleImageChange } from "../Helpers/uploadtoBucket";

function AddDeliveryBoy() {
  const [deliveryBoy, setDeliveryBoy] = useState({
    name: "",
    age: "",
    aadhaar: "",
    address: "",
    pinCode: "",
    mobileNumber: "",
    email: "",
  });
  const [adhaarUrl, setAdhaarUrl] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [aadhaarFile, setAadhaarFile] = useState("");
  const [photoFile, setPhotoFile] = useState("");
  const [deliveryBoyData, setDeliveryBoyData] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Keep track of the row being edited
  const [editedData, setEditedData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeliveryBoy({ ...deliveryBoy, [name]: value });
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API}/get/all/delivery/boy`);
      setDeliveryBoyData(response.data);
      console.log("boy details", response.data);
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(deliveryBoyData, "delivery");

  const handleEdit = (index) => {
    setEditIndex(index); // Set the row to be edited
    setEditedData(deliveryBoyData[index]); // Store the current data for editing
  };

  const handleSave = async (index, id) => {
    try {
      // Here, you can send the updated data to the server using an API call
      await axios.put(`${API}/update/delivery/${id}`, editedData);
      // Update the local state after saving
      const updatedData = [...deliveryBoyData];
      updatedData[index] = editedData;
      setDeliveryBoyData(updatedData);
      setEditIndex(null); // Exit edit mode
      toast.success("Data updated successfully");
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Error updating data");
    }
  };

  // Handle Delete Button Click
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API}/delete/delivery/${id}`);
      const filteredData = deliveryBoyData.filter((item) => item.id !== id);
      // setDeliveryBoyData(filteredData);
      fetchData();
      toast.success("Data deleted successfully");
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Error deleting data");
    }
  };

  // Handle Input Change for Edit Mode
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };
  console.log(deliveryBoyData, "delivery");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name: deliveryBoy.name,
      age: deliveryBoy.age,
      aadharNo: deliveryBoy.aadhaar,
      address: deliveryBoy.address,
      pinCode: deliveryBoy.pinCode,
      mobileNo: deliveryBoy.mobileNumber,
      email: deliveryBoy.email,
      aadharImage: adhaarUrl,
      uploadSelfie: photoUrl,
    };
    try {
      const response = await axios.post(`${API}/add/delivery/boy`, payload);
      if (response.data) {
        console.log("deliveryboy data", response.data);
        toast.success("Delivery Boy Added Successfully");
        fetchData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.response.data.message);
    }
    console.log("Delivery Boy Details: ", deliveryBoy);
  };

  const handleAadhaar = (index) => {
    const aadharImage = deliveryBoyData[index].aadharImage;
    window.open(aadharImage, "_blank");
  };
  const handleSelfie = (index) => {
    const uploadSelfie = deliveryBoyData[index].uploadSelfie;
    window.open(uploadSelfie, "_blank");
  };
  return (
    <div className="flex justify-center flex-col items-center min-h-full my-8 bg-gray-100">
      <div className="w-full max-w-6xl p-8 bg-white shadow-lg mb-4 rounded-lg">
        <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">
          Add Delivery Boy
        </h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-6">
          {/* Column 1 */}
          <div className="space-y-4">
            {/* Delivery Boy Name */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="name"
              >
                Delivery Boy Name
              </label>
              <input
                type="text"
                name="name"
                value={deliveryBoy.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Name"
                required
              />
            </div>

            {/* Aadhaar Card Number */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="aadhaar"
              >
                Aadhaar Card Number
              </label>
              <input
                type="text"
                name="aadhaar"
                value={deliveryBoy.aadhaar}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Aadhaar Number"
                required
              />
            </div>

            {/* Aadhaar Card Upload */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="aadhaarFile"
              >
                Upload Aadhaar Card
              </label>
              <input
                type="file"
                name="aadhaarFile"
                onChange={(e) =>
                  handleImageChange(e, setAdhaarUrl, setAadhaarFile)
                }
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          </div>

          {/* Column 2 */}
          <div className="space-y-4">
            {/* Age */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="age"
              >
                Age
              </label>
              <input
                type="number"
                name="age"
                value={deliveryBoy.age}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Age"
                required
              />
            </div>

            {/* Address */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="address"
              >
                Address
              </label>
              <textarea
                name="address"
                value={deliveryBoy.address}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Address"
                required
              />
            </div>

            {/* Pin Code */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="pinCode"
              >
                Pin Code
              </label>
              <input
                type="text"
                name="pinCode"
                value={deliveryBoy.pinCode}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Pin Code"
                required
              />
            </div>
          </div>

          {/* Column 3 */}
          <div className="space-y-4">
            {/* Mobile Number */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="mobileNumber"
              >
                Mobile Number
              </label>
              <input
                type="text"
                name="mobileNumber"
                value={deliveryBoy.mobileNumber}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Mobile Number"
                required
              />
            </div>

            {/* Email */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                value={deliveryBoy.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Email"
                required
              />
            </div>

            {/* Photo Upload */}
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="photo"
              >
                Upload Photo
              </label>
              <input
                type="file"
                name="photo"
                onChange={(e) =>
                  handleImageChange(e, setPhotoUrl, setPhotoFile)
                }
                className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-span-3 flex justify-center mt-4">
            <button
              type="submit"
              className="bg-blue-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              Add Delivery Boy
            </button>
          </div>
        </form>
      </div>
      <div className="flex  w-full p-8 flex-col  overflow-x-auto min-h-screen  bg-gray-100">
        <table className="table-auto border-collapse w-full mx-auto bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Age</th>
              <th className="border px-4 py-2">Mobile No</th>
              <th className="border px-4 py-2">Aadhar No</th>
              <th className="border px-4 py-2">Status</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Pin Code</th>

              <th className="border px-4 py-2">Images</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {deliveryBoyData?.map((boy, index) => (
              <tr key={boy.id}>
                {editIndex === index ? (
                  <>
                    <td className="border px-4 py-2">
                      <input
                        type="text"
                        name="name"
                        value={editedData.name}
                        onChange={handleEditChange}
                        className="border"
                      />
                    </td>
                    <td className="border px-4 py-2">
                      <input
                        type="text"
                        name="mobileNo"
                        value={editedData.mobileNo}
                        onChange={handleEditChange}
                        className="border"
                      />
                    </td>
                    <td className="border px-4 py-2">
                      <input
                        type="text"
                        name="aadharNo"
                        value={editedData.aadharNo}
                        onChange={handleEditChange}
                        className="border"
                      />
                    </td>
                    <td className="border px-4 py-2">
                      <input
                        type="text"
                        name="email"
                        value={editedData.email}
                        onChange={handleEditChange}
                        className="border"
                      />
                    </td>
                    {/* <td className="border px-4 py-2">
                      <input
                        type="text"
                        name="pinCode"
                        value={editedData.pinCode}
                        onChange={handleEditChange}
                        className="border"
                      />
                    </td> */}
                    {/* <td className="border px-4 py-2">
                      <input
                        type="text"
                        name="pinCode"
                        value={editedData.pinCode}
                        onChange={handleEditChange}
                        className="border"
                      />
                    </td> */}
                    <td className="border px-4 py-2">{boy.status}</td>
                    <td className="border px-4 py-2">{boy.email}</td>

                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleSave(index, boy._id)}
                        className="bg-green-500 text-white px-2 py-1 rounded"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditIndex(null)}
                        className="bg-red-500 text-white px-2 py-1 ml-2 rounded"
                      >
                        Cancel
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="border px-4 py-2">{boy.name}</td>
                    <td className="border px-4 py-2">{boy.age}</td>
                    <td className="border px-4 py-2">{boy.mobileNo}</td>
                    <td className="border px-4 py-2">{boy.aadharNo}</td>
                    <td className="border px-4 py-2">{boy.status}</td>
                    <td className="border px-4 py-2">{boy.email}</td>
                    <td className="border px-4 py-2">{boy.pinCode}</td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleAadhaar(index)}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Aadhaar
                      </button>
                      <button
                        onClick={() => handleSelfie(index)}
                        className="bg-red-500 text-white px-2 py-1 ml-2 rounded"
                      >
                        Selfie
                      </button>
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleEdit(index)}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(boy._id)}
                        className="bg-red-500 text-white px-2 py-1 ml-2 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AddDeliveryBoy;
