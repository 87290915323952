import React, { useState, useEffect } from "react";
import logo2 from "../images/logo2.jpeg";
import { useLocation } from "react-router-dom"; // To detect route changes

function AdminHome() {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => setIsTransitioning(false), 1000); // Reset after the duration of the animation
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <div className="bg-purple-900 w-full flex flex-row justify-between items-center">
      <img
        src={logo2}
        alt="Logo 2"
        className={`h-screen w-screen transition-all duration-1000 ease-in-out ${
          isTransitioning ? "animate-transformReduce" : ""
        }`}
      />
    </div>
  );
}

export default AdminHome;
