import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

// Import custom marker icon (default or customize)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
});

const DeliveryBoyMap = () => {
  const [deliveryBoys, setDeliveryBoys] = useState([]);

  // Fetch delivery boy data
  useEffect(() => {
    const fetchDeliveryBoys = () => {
      axios
        .get(
          "https://api.dev.food.delivery.tdrsoftware.in/get/all/delivery/boy"
        )
        .then((response) => {
          console.log("Fetched delivery boys:", response.data); // Log fetched data
          setDeliveryBoys(response.data);
        })
        .catch((error) =>
          console.error("Error fetching delivery boys:", error)
        );
    };

    // Fetch data initially
    fetchDeliveryBoys();

    // Poll every 10 seconds to get updated locations
    const intervalId = setInterval(fetchDeliveryBoys, 10000); // Poll every 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Delivery Boy Location Tracker</h1>

      {/* Map container */}
      <MapContainer
        center={[22.5726, 88.3639]}
        zoom={13}
        className="h-4/5 w-4/5 rounded-lg shadow-lg"
      >
        {/* Tile layer from OpenStreetMap */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Plot delivery boys on the map */}
        {deliveryBoys.map((boy) => (
          <Marker key={boy._id} position={[boy.latitude, boy.longitude]}>
            <Popup>
              <div className="p-2">
                <h3 className="text-lg font-semibold">{boy.name}</h3>
                <p>Mobile: {boy.mobileNo}</p>
                <p>Address: {boy.address}</p>
                <p>Status: {boy.status}</p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default DeliveryBoyMap;
