import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaClipboardList, FaDollarSign } from "react-icons/fa";

const ReportComponent = () => {
  const [orders, setOrders] = useState([]);
  const [dailyReport, setDailyReport] = useState([]);
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://api.dev.food.delivery.tdrsoftware.in/get/all/order"
        );
        setOrders(response.data.data);
        generateReports(response.data.data, selectedMonth);
      } catch (error) {
        console.error("Error fetching the orders", error);
      }
    };
    fetchOrders();
  }, [selectedMonth]); // Fetch new reports when the month changes

  const generateReports = (orders, month) => {
    const today = new Date();

    // Daily report
    const daily = orders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      return orderDate.toDateString() === today.toDateString();
    });
    setDailyReport(daily);

    // Monthly report
    const monthly = orders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      return (
        orderDate.getMonth() === month &&
        orderDate.getFullYear() === today.getFullYear()
      );
    });
    setMonthlyReport(monthly);
  };

  const calculateTotalPrice = (orders) => {
    return orders.reduce((total, order) => total + order.totalPrice, 0);
  };

  const toggleOrderDetails = (orderId) => {
    setSelectedOrderId(selectedOrderId === orderId ? null : orderId); // Toggle the clicked order
  };

  const handleMonthChange = (event) => {
    const month = parseInt(event.target.value, 10);
    setSelectedMonth(month);
    generateReports(orders, month); // Generate the report for the selected month
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        Reports Dashboard
      </h1>

      {/* Month Selector */}
      <div className="mb-6">
        <label className="text-lg font-semibold mr-4">Select Month: </label>
        <select
          value={selectedMonth}
          onChange={handleMonthChange}
          className="border rounded p-2"
        >
          {[...Array(12)].map((_, index) => (
            <option key={index} value={index}>
              {new Date(0, index).toLocaleString("en", { month: "long" })}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
        {/* Daily Report */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition duration-500 hover:scale-105">
          <h2 className="text-xl font-bold text-blue-600 mb-4 flex items-center">
            <FaClipboardList className="mr-2" /> Daily Report
          </h2>
          <p className="text-lg font-semibold text-gray-700">
            Total Orders:{" "}
            <span className="text-blue-600">{dailyReport.length}</span>
          </p>
          <p className="text-lg font-semibold text-gray-700">
            Total Price: ₹
            <span className="text-blue-600">
              {calculateTotalPrice(dailyReport)}
            </span>
          </p>

          <ul className="mt-4">
            {dailyReport?.map((order) => (
              <li
                key={order._id}
                className="bg-gray-50 p-3 rounded-lg mb-2 shadow-sm cursor-pointer"
                onClick={() => toggleOrderDetails(order._id)} // Click to show/hide details
              >
                <div>
                  <strong>Order ID:</strong>{" "}
                  <span className="text-gray-800 font-medium">
                    {order.orderId}
                  </span>{" "}
                  - ₹{order.totalPrice}
                </div>
                {selectedOrderId === order._id && (
                  <div className="mt-2 p-2 bg-gray-200 rounded">
                    <h4 className="font-semibold">Order Details</h4>
                    <p>
                      <strong>Customer Name:</strong> {order.userAddress?.name}
                    </p>
                    <p>
                      <strong>Status:</strong> {order.status}
                    </p>
                    <p>
                      <strong>Order Date:</strong>{" "}
                      {new Date(order.createdAt).toLocaleString()}
                    </p>
                    <p>
                      <strong>Items:</strong>
                    </p>
                    <ul>
                      {order.addToCart?.cartIds?.map((item, index) => (
                        <li key={index}>
                          {item.itemName} - Qty: {item.count}, Price: ₹
                          {item.itemRate}
                          {item.image && (
                            <img
                              src={item.image}
                              alt={item.itemName}
                              className="w-16 h-16 mt-2"
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Monthly Report */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition duration-500 hover:scale-105">
          <h2 className="text-xl font-bold text-green-600 mb-4 flex items-center">
            <FaDollarSign className="mr-2" /> Monthly Report
          </h2>
          <p className="text-lg font-semibold text-gray-700">
            Total Orders:{" "}
            <span className="text-green-600">{monthlyReport.length}</span>
          </p>
          <p className="text-lg font-semibold text-gray-700">
            Total Price: ₹
            <span className="text-green-600">
              {calculateTotalPrice(monthlyReport)}
            </span>
          </p>

          <ul className="mt-4">
            {monthlyReport?.map((order) => (
              <li
                key={order._id}
                className="bg-gray-50 p-3 rounded-lg mb-2 shadow-sm cursor-pointer"
                onClick={() => toggleOrderDetails(order._id)} // Click to show/hide details
              >
                <div>
                  <strong>Order ID:</strong>{" "}
                  <span className="text-gray-800 font-medium">
                    {order.orderId}
                  </span>{" "}
                  - ₹{order.totalPrice}
                </div>
                {selectedOrderId === order._id && (
                  <div className="mt-2 p-2 bg-gray-200 rounded">
                    <h4 className="font-semibold">Order Details</h4>
                    <p>
                      <strong>Customer Name:</strong> {order.userAddress?.name}
                    </p>
                    <p>
                      <strong>Status:</strong> {order.status}
                    </p>
                    <p>
                      <strong>Order Date:</strong>{" "}
                      {new Date(order.createdAt).toLocaleString()}
                    </p>
                    <p>
                      <strong>Items:</strong>
                    </p>
                    <ul>
                      {order?.addToCart?.cartIds?.map((item, index) => (
                        <li key={index}>
                          {item.itemName} - Qty: {item.count}, Price: ₹
                          {item.itemRate}
                          {item.image && (
                            <img
                              src={item.image}
                              alt={item.itemName}
                              className="w-16 h-16 mt-2"
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ReportComponent;
