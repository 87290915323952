import React, { useEffect, useState } from "react";
import axios from "axios";

const AssignedOrder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        "https://api.dev.food.delivery.tdrsoftware.in/get/all/confirm/order"
      );
      if (response.data && response.data.success) {
        const revOrders = response.data.data.reverse();
        setOrders(revOrders);
      }
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Confirmed Orders</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {orders.map((order) => (
          <div
            key={order._id}
            className="bg-white shadow-lg rounded-lg p-4 border border-gray-200"
          >
            <h2 className="text-xl font-semibold">
              Order ID: {order.orderId.orderId}
            </h2>
            <p className="text-gray-600">
              Total Price: ₹{order.orderId.totalPrice}
            </p>
            <p className="text-gray-600">Status: {order.status}</p>
            <p className="text-gray-600">
              Created At: {new Date(order.createdAt).toLocaleDateString()}{" "}
              {new Date(order.createdAt).toLocaleTimeString()}
            </p>

            {/* Delivery Boy Information */}
            {order.deliveryBoyId && (
              <p className="text-gray-600">
                Delivery Boy: {order.deliveryBoyId.name}
              </p>
            )}

            <div className="mt-2">
              <h3 className="font-bold">Items:</h3>
              {order?.orderId?.addToCart?.cartIds.map((item) => (
                <div
                  key={item._id}
                  className="flex items-center justify-between my-2 border-b py-2"
                >
                  <img
                    src={item.image}
                    alt={item.itemName}
                    className="w-16 h-16 rounded"
                  />
                  <div className="ml-2">
                    <p className="font-semibold">{item.itemName}</p>
                    <p className="text-gray-500">Price: ₹{item.itemRate}</p>
                    <p className="text-gray-500">Quantity: {item.count}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssignedOrder;
