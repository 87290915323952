import React from "react";
import Cookies from "js-cookie";

const Logout = () => {
  const logoutHandler = () => {
    Cookies.remove("authToken");
    window.location.href = "/login";
  };
  return (
    <div className="mt-8 justify-center bg-white flex">
      <button
        onClick={logoutHandler}
        className="bg-red-800 py-2 px-8 font-bold text-white rounded-lg hover:bg-red-900 transition duration-200"
      >
        Logout
      </button>
    </div>
  );
};

export default Logout;
