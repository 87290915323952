import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print"; // Import useReactToPrint
import { API } from "../utils/apk";
import logo1 from "../images/logo1.jpeg";
import { toast } from "react-toastify";
import Notification from "../component/Notification";

const OrderDetails = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [billOrderId, setBillOrderId] = useState(null); // State to store the selected order for the bill
  const previousOrdersLength = useRef(0);
  const [showNotification, setShowNotification] = useState(false); // State for showing notification
  const [notificationMessage, setNotificationMessage] = useState("");
  // Reference to the bill component to print
  const componentRef = useRef();

  // Fetch orders from the API using Axios
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(API + "/get/all/order");
        const newOrders = response.data.data;
        if (newOrders.length > previousOrdersLength.current) {
          setNotificationMessage("New order received!"); // Set custom message
          setShowNotification(true); // Show notification

          // Hide the notification after 3 seconds
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
        }
        setOrders(newOrders.reverse());
        previousOrdersLength.current = newOrders.length;
        console.log(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders");
        setLoading(false);
      }
    };

    fetchOrders();
    const intervalId = setInterval(fetchOrders, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Fetch delivery boys from the API using Axios
  const fetchDeliveryBoys = async () => {
    try {
      const response = await axios.get(API + "/get/all/delivery/boy");
      setDeliveryBoys(response.data); // Assuming the response data contains delivery boys
    } catch (err) {
      console.error("Error fetching delivery boys:", err);
      setError("Failed to fetch delivery boys");
    }
  };

  // Handle the opening of the modal
  const openModal = (orderId) => {
    setSelectedOrder(orderId);
    fetchDeliveryBoys(); // Fetch delivery boys when the modal opens
    setIsModalOpen(true);
  };

  // Handle the closing of the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle the selection of a delivery boy
  const selectDeliveryBoy = (deliveryBoy) => {
    setSelectedDeliveryBoy((prev) => ({
      ...prev,
      [selectedOrder]: deliveryBoy.name, // Set the delivery boy for the selected order
      deliveryBoyId: deliveryBoy._id, // Set
    }));
    setIsModalOpen(false); // Close modal after selection
  };

  // Handle bill generation using useReactToPrint
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  // const confirmDeliveryMan = async (orderId) => {
  //   console.log("orderId", orderId, "deliveryBoyId", selectedDeliveryBoy);
  //   const name = selectedDeliveryBoy[orderId];
  //   console.log(
  //     "deliveryboy id ",
  //     selectedDeliveryBoy.deliveryBoyId,
  //     "orderId",
  //     orderId
  //   );
  //   try {
  //     const response = await axios.post(API + "/confirm/order", {
  //       orderId,
  //       deliveryBoyId: selectedDeliveryBoy.deliveryBoyId,
  //     });
  //     if (response.data) {
  //       console.log("Delivery Man Confirmed", response.data);

  //       const updateResponse = await axios.put(
  //         `${API}/update/order/status/${orderId}`
  //       );
  //       if (updateResponse.data) {
  //         console.log("Order status updated successfully");
  //         fetchDeliveryBoys();
  //       } else {
  //         console.error("Failed to update order status");
  //       }
  //     }
  //     toast.success(`Order successfully assigned to ${name?.toUpperCase()}`);
  //   } catch (error) {
  //     console.error("Error confirming delivery man:", error);
  //   }
  // };

  // // Trigger bill generation for a specific order
  const generateBill = (orderId) => {
    console.log("Generating bill for order:", orderId);
    setBillOrderId(orderId); // Set the order ID to print

    // Delay to ensure UI updates before printing
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  // Loading or Error Handling
  if (loading) {
    return <p className="text-center mt-20 text-gray-500">Loading orders...</p>;
  }

  if (error) {
    return <p className="text-center mt-20 text-red-500">{error}</p>;
  }

  const confirmDeliveryMan = async (orderId) => {
    console.log("orderId", orderId, "deliveryBoyId", selectedDeliveryBoy);
    const name = selectedDeliveryBoy[orderId];
    try {
        const response = await axios.post(`${API}/confirm/order`, {
            orderId,
            deliveryBoyId: selectedDeliveryBoy.deliveryBoyId,
        });

        if (response.data) {
            console.log("Delivery Man Confirmed", response.data);

            const updateResponse = await axios.put(
                `${API}/update/order/status/${orderId}`
            );

            if (updateResponse.data) {
                console.log("Order status updated successfully");

                // Remove the confirmed order from the orders state
                setOrders((prevOrders) =>
                    prevOrders.filter((order) => order._id !== orderId)
                );
                fetchDeliveryBoys();
            } else {
                console.error("Failed to update order status");
            }

            toast.success(`Order successfully assigned to ${name?.toUpperCase()}`);
        }
    } catch (error) {
        console.error("Error confirming delivery man:", error);
    }
};

  return (
    <div className="bg-gradient-to-b from-blue-100 to-blue-200 min-h-screen py-10">
      <h1 className="text-5xl font-bold text-center text-gray-800 mb-10">
        Order Details
      </h1>

      <div className="max-w-6xl mx-auto px-4">
        {orders.map((order) => (
          <div
            key={order._id}
            className="bg-white shadow-xl rounded-lg mb-8 p-6 hover:shadow-2xl transition duration-300 transform hover:scale-105"
          >
            <div>
              <h2 className="text-3xl font-semibold text-gray-800 mb-2">
                Order ID: {order.orderId}
              </h2>
              {order.createdAt && (
                <p className="text-gray-600">
                  <strong>Order Placed On:</strong>{" "}
                  {new Date(order.createdAt).toLocaleDateString()}{" "}
                  {new Date(order.createdAt).toLocaleTimeString()}
                </p>
              )}
              <p className="text-xl font-medium text-indigo-600 mb-4">
                Total Price: ₹{order.totalPrice?.toFixed(2)}
              </p>
            </div>

            {/* Items in the Cart */}
            <div className="mb-6">
              <h3 className="text-2xl font-medium text-gray-700 mb-2">
                Items in Cart
              </h3>
              {order.addToCart?.cartIds?.map((item) => (
                <div
                  key={item._id}
                  className="flex items-center space-x-4 bg-gray-50 p-4 rounded-lg shadow-md mb-4"
                >
                  {item.image ? (
                    <img
                      src={item.image}
                      alt={item.itemName}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-20 h-20 bg-gray-200 rounded-lg" />
                  )}
                  <div>
                    <p className="text-xl text-gray-700 font-semibold">
                      {item.itemName}
                    </p>
                    <p className="text-gray-600">
                      <strong>Quantity:</strong> {item?.count}
                    </p>
                    <p className="text-gray-600">
                      <strong>Price:</strong> ₹{item.itemRate?.toFixed(2)}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/* Selected Delivery Boy */}
            {selectedDeliveryBoy[order._id] && (
              <p className="text-xl text-green-600">
                Delivery Boy: {selectedDeliveryBoy[order._id]}
              </p>
            )}

            {/* Add Delivery Boy Button */}
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
              onClick={() => openModal(order._id)}
            >
              Add Delivery Boy
            </button>
            <button
              className="mt-4 ml-4 px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600"
              onClick={() => confirmDeliveryMan(order._id)}
            >
              Confirm
            </button>

            {/* Generate Bill Button */}
            <button
              className="mt-4 ml-4 px-4 py-2 bg-yellow-500 text-white rounded-lg shadow hover:bg-yellow-600"
              onClick={() => generateBill(order._id)}
            >
              Generate Bill
            </button>
          </div>
        ))}
      </div>
      {showNotification && (
        <Notification
          message={notificationMessage}
          onClose={() => setShowNotification(false)}
        />
      )}
      {/* Delivery Boy Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h3 className="text-2xl font-semibold mb-4">Select Delivery Boy</h3>
            <ul>
              {deliveryBoys.map((boy) => (
                <li
                  key={boy._id}
                  className="flex items-center mb-4 cursor-pointer hover:bg-gray-100 p-2 rounded"
                  onClick={() => selectDeliveryBoy(boy)}
                >
                  <img
                    src={boy.uploadSelfie}
                    alt={boy.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="text-lg font-medium">{boy.name}</p>
                    <p className="text-gray-600">{boy.mobileNo}</p>
                  </div>
                </li>
              ))}
            </ul>
            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Hidden Bill Section for PDF */}
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          maxWidth: "600px",
          margin: "auto",
          marginTop: "20px",
        }}
        ref={componentRef}
      >
        <img src={logo1} alt="Logo 2" className="h-20 mx-auto" />
        <h1 className="text-2xl font-bold text-center text-blue-900">
          SURAJ HOTEL
        </h1>
        {orders
          .filter((order) => order._id === billOrderId)
          .map((order) => (
            <div key={order._id} className="p-4">
              {console.log(order)}
              <h2 className="text-xl font-bold text-left">Order Bill</h2>

              {/* Display the Order ID if available */}
              <p className="mt-4">
                <strong>Order ID:</strong> {order.orderId}
              </p>

              {/* Display each item in the order */}
              <h3 className="mt-6 text-xl font-semibold">Order Items:</h3>
              <div className="border-b border-gray-300 mb-4">
                <div className="flex justify-between font-bold">
                  <span className="flex-1">Item Name</span>
                  <span className="flex-1 text-center">Quantity</span>
                  <span className="flex-1 text-center">Rate</span>
                  <span className="flex-1 text-center">SGST</span>
                  <span className="flex-1 text-center">CGST</span>
                </div>
              </div>
              {order.addToCart?.cartIds?.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-between py-2 border-b border-gray-200"
                >
                  <span className="flex-1">{item.itemName}</span>
                  <span className="flex-1 text-center">{item.count}</span>
                  <span className="flex-1 text-center">
                    ₹{item.itemRate.toFixed(2)}
                  </span>
                  <span className="flex-1 text-center">
                    ₹{item.sgst.toFixed(2)}
                  </span>
                  <span className="flex-1 text-center">
                    ₹{item.cgst.toFixed(2)}
                  </span>
                </div>
              ))}

              {/* Display total price */}
              <h1 className="mt-4 text-xl font-bold text-right">
                Total Price:{" "}
                <span className="text-red-600">
                  ₹{order.totalPrice.toFixed(2)}
                </span>
              </h1>

              {/* Add more bill details here as needed */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrderDetails;
