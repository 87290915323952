import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API } from "../utils/apk";
import Cookies from "js-cookie";
import { handleImageChange } from "../Helpers/uploadtoBucket";

function Register() {
  const [itemName, setItemName] = useState("");
  const [itemCategory, setItemCategory] = useState("");
  const [itemCode, setItemCode] = useState();
  const [itemRate, setItemRate] = useState();
  const [cgst, setCgst] = useState();
  const [sgst, setSgst] = useState();
  const [unit, setUnit] = useState();
  const [type, setType] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [offer, setOffer] = useState();
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [newCategory, setNewCategory] = useState("");
  const [items, setItems] = useState([]); // State to hold items
  const token = Cookies.get("authToken");
  const [editId, setEditId] = useState(null); // Track the ID of the row being edited
  const [editedItem, setEditedItem] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [isManualEntry, setIsManualEntry] = useState(false);

  const uniqueCategories = [...new Set(items.map((item) => item.itemCategory))];
  // console.log("uniquecategories", uniqueCategories);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API}/get/all/item`);
      // console.log(response.data.reverse());
      setItems(response.data);
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (itemRate && offer) {
      const calculatedOfferPrice = itemRate - itemRate * (offer / 100);
      setOfferPrice(calculatedOfferPrice.toFixed(2)); // Rounds to 2 decimal places
    }
  }, [itemRate, offer]);
  useEffect(() => {
    const fetchItemByCode = async () => {
      if (itemCode && itemName === "") {
        try {
          const response = await axios.get(`${API}/get/item/by/${itemCode}`);
          if (response.data) {
            const itemData = response.data;
            if (Object.keys(itemData).length > 0) {
              setItemName(itemData.itemName || "");
              setItemCategory(itemData.itemCategory || "");
              setItemRate(itemData.itemRate || "");
              setCgst(itemData.cgst || "");
              setSgst(itemData.sgst || "");
              setUnit(itemData.unit || "");
              setType(itemData.type || "");
              setImageUrl(itemData.image || "");
              setOffer(itemData.offer || "");
              setOfferPrice(itemData.offerPrice || "");
              setFromDate(itemData.fromDate || "");
              setToDate(itemData.toDate || "");
              toast.success("Item details fetched successfully.");
            } else {
              setItemName("");
              setItemCategory("");
              setItemRate("");
              setCgst("");
              setSgst("");
              setUnit("");
              setType("");
              setOffer("");
              setImageUrl(null);
              setOfferPrice("");
              setFromDate("");
              setToDate("");
            }
          }
        } catch (error) {
          console.log(
            "Error fetching item by code:",
            error.response?.data?.message || error.message
          );
        }
      }
    };
    fetchItemByCode();
  }, [itemCode]);
  const clearData = () => {
    setItemCode("");
    setItemName("");
    setItemCategory("");
    setItemRate("");
    setCgst("");
    setSgst("");
    setUnit("");
    setType("");
    setOffer("");
    setImageUrl(null);
    setOfferPrice("");
    setFromDate("");
    setToDate("");
  };
  useEffect(() => {
    const fetchItemByCode = async () => {
      if (itemName) {
        try {
          const response = await axios.get(`${API}/get/item/info/${itemName}`);
          console.log("response name", response.data);
          if (response.data) {
            const itemData = response.data;
            if (Object.keys(itemData).length > 0) {
              setItemCode(itemData.itemCode || "");
              setItemCategory(itemData.itemCategory || "");
              setItemRate(itemData.itemRate || "");
              setCgst(itemData.cgst || "");
              setSgst(itemData.sgst || "");
              setUnit(itemData.unit || "");
              setType(itemData.type || "");
              setImageUrl(itemData.image || "");
              setOffer(itemData.offer || "");
              setOfferPrice(itemData.offerPrice || "");
              setFromDate(itemData.fromDate || "");
              setToDate(itemData.toDate || "");
              // toast.success("Item details fetched successfully.");
            } else {
              setItemCode("");
              setItemCategory("");
              setItemRate("");
              setCgst("");
              setSgst("");
              setUnit("");
              setType("");
              setOffer("");
              setImageUrl(null);
              setOfferPrice("");
              setFromDate("");
              setToDate("");
            }
          }
        } catch (error) {
          console.log(
            "Error fetching item by code:",
            error.response?.data?.message || error.message
          );
        }
      }
    };
    fetchItemByCode();
  }, [itemName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      itemCode,
      itemName,
      itemCategory,
      itemRate,
      sgst,
      cgst,
      unit,
      type,
      image: imageUrl,
      offer,
      fromDate,
      toDate,
      offerPrice,
    };
    try {
      const response = await axios.post(API + "/create/item", payload);
      if (response.data) {
        toast.success("Item Created Successfully");
        // Reset form fields
        setItemName("");
        setItemCategory("");
        setItemCode("");
        setCgst("");
        setSgst("");
        setImage("");
        setItemRate("");
        setOffer("");
        setType("");
        setUnit("");
        setOfferPrice("");
        setFromDate("");
        setToDate("");
      }
      fetchData();
    } catch (error) {
      console.log("error while creating item", error.response.data.message);

      toast.error(error.response.data.message);
      console.log("token", token);
      console.log(payload);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (id) => {
    const itemToEdit = items.find((item) => item._id === id);
    setEditId(id);
    setEditedItem({ ...itemToEdit });
    // toast.success("Item Edited Successfully");
  };

  // Handle when "Save" is clicked
  const handleSave = async (id) => {
    try {
      const response = await axios.put(API + "/update/item/" + id, {
        ...editedItem,
      });
      if (response.data) {
        const updatedItems = items.map((item) =>
          item._id === id ? editedItem : item
        ); // Update the item with the new edited values
        console.log("updatedItems", id);
        setItems(updatedItems); // Save updated items
        setEditId(null);
        toast.success("Item Saved Successfully");
      }
    } catch (error) {
      console.log("error while editing item", error);
      toast.error(error.response.data.message);
    }
    // Exit edit mode
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Handle input changes

  const handleDelete = async (id) => {
    console.log("id", id);
    try {
      const response = await axios.delete(API + "/delete/item/" + id);
      if (response.data) {
        toast.success("Item Deleted Successfully");
        fetchData();
      }
    } catch (error) {
      console.log("error while deleting item", error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleStatusToggle = async (id) => {
    try {
      const response = await axios.put(API + "/update/status/" + id);
      if (response.data) {
        toast.success("Item Status Updated Successfully");
        fetchData();
      }
    } catch (error) {
      console.log(
        "error while updating item status",
        error.response.data.message
      );
      toast.error(error.response.data.message);
    }
  };
  const handleCategorySubmit = () => {
    if (newCategory.trim()) {
      // Add a new item to the items array with the new category
      const newItem = {
        itemCategory: newCategory.trim(), // Set the new category
        itemName: "", // You can add other default fields here
        itemCode: "", // Or leave these empty if not needed
      };

      // Update the items array without selecting the new category
      setItems((prevItems) => [...prevItems, newItem]);

      // Don't set the new category as selected, let the user manually select
      // setItemCategory(""); // This line is removed to avoid auto-selecting the new category

      setShowModal(false); // Close the modal
      setNewCategory(""); // Clear the input field
    }
  };

  return (
    <div className="w-full  mx-auto p-4  shadow-md mt-4 mb-8 rounded-lg  bg-gray-40 ">
      {/* <h2 className="text-lg font-semibold mb-4">Item Form</h2> */}
      <form onSubmit={handleSubmit}>
        {/* Input Fields */}
        <div className="w-full flex flex-row justify-between flex-wrap bg-blue-200 border-b border-dashed  border-white px-8 shadow-md py-4">
          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="itemCode">
              Item Code
            </label>
            <input
              type="text"
              id="itemCode"
              name="itemCode"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={itemCode}
              onChange={(e) => setItemCode(e.target.value)}
              onClick={clearData}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="itemName">
              Item Name
            </label>
            <input
              type="text"
              id="itemName"
              name="itemName"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="itemCategory">
              Category
            </label>

            <div className="flex items-center">
              <select
                id="itemCategory"
                className="md:min-w-72 min-w-20 border border-gray-300 rounded p-2"
                value={itemCategory || ""} // Ensure the default state is empty (i.e., "Select Category")
                onChange={(e) => {
                  const selectedCategory = e.target.value;
                  if (selectedCategory) {
                    setItemCategory(selectedCategory); // Update the category state when an option is selected
                    setType(selectedCategory); // Optional: Update type if needed
                  } else {
                    setItemCategory(""); // Reset if no valid category is selected
                  }
                }}
                required
              >
                <option value="">Select Category</option>
                {uniqueCategories?.map((item, index) => (
                  <option key={index} value={item.trim()}>
                    {item.trim()}
                  </option>
                ))}
              </select>

              <button
                type="button"
                className="bg-green-600 text-white rounded font-bold ml-2 px-2"
                onClick={() => setShowModal(true)} // Open modal to add a new category
              >
                +
              </button>
            </div>
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="itemRate">
              Item Rate
            </label>
            <input
              type="number"
              id="itemRate"
              name="itemRate"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={itemRate}
              onChange={(e) => setItemRate(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="w-full flex flex-row flex-wrap justify-between bg-blue-200 border-b border-dashed border-white px-8 py-4">
          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="cgst">
              CGST %
            </label>
            <input
              type="number"
              id="cgst"
              name="cgst"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={cgst}
              onChange={(e) => {
                const value = e.target.value;
                setCgst(value);
                setSgst(value); // Update SGST to match CGST
              }}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="sgst">
              SGST %
            </label>
            <input
              type="number"
              id="sgst"
              name="sgst"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={sgst}
              onChange={(e) => setSgst(e.target.value)}
              required
            />
          </div>

          <div className="mb-4 ">
            <label className="block mb-1 font-bold" htmlFor="unit">
              Unit
            </label>
            <select
              id="unit"
              className="md:min-w-80 min-w-48 border border-gray-300 rounded p-2"
              value={unit}
              onChange={(e) => setUnit(e.target.value)} // Update to setUnit
              required
            >
              <option value="">Select Unit</option>
              <option value="pcs">Pcs</option>
              <option value="plate">Plate</option>
            </select>
          </div>

          <div className="mb-4 ">
            <label className="block mb-1 font-bold" htmlFor="type">
              Type
            </label>
            <select
              id="type"
              className="md:min-w-80 min-w-48 border border-gray-300 rounded p-2"
              value={type}
              onChange={(e) => setType(e.target.value)} // This is correct
              required
            >
              <option value="">Select Type</option>
              <option value="full">Full</option>
              <option value="half">Half</option>
              <option value="quarter">Quarter</option>
            </select>
          </div>
        </div>
        <div className="w-full flex flex-row flex-wrap justify-between bg-blue-200 border-b border-dashed border-white px-8 py-4">
          <div>
            <label className="block mb-1 font-bold" htmlFor="fromDate">
              From Date:
            </label>
            <input
              type="date"
              id="fromDate"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              required
            />
          </div>

          {/* To Date */}
          <div>
            <label className="block mb-1 font-bold" htmlFor="toDate">
              To Date:
            </label>
            <input
              type="date"
              id="toDate"
              value={toDate}
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              onChange={(e) => setToDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="Offer">
              Offer
            </label>
            <input
              type="number"
              id="Offer"
              name="Offer"
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              value={offer}
              onChange={(e) => setOffer(e.target.value)}
              required
            />
          </div>
          {/* Offer Price */}
          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="offerPrice">
              Offer Price
            </label>
            <input
              type="number"
              id="offerPrice"
              value={offerPrice}
              className="md:min-w-80 min-w-20 border border-gray-300 rounded p-2"
              readOnly
              placeholder="Offer Price will be calculated"
            />
          </div>
        </div>

        <div className="w-full flex flex-row justify-between bg-blue-200 border-b border-dashed border-white px-8 pt-4">
          <div className="mb-4">
            <label className="block mb-1 font-bold" htmlFor="image">
              Image
            </label>
            <input
              type="file"
              id="image"
              className="w-full border border-gray-300 rounded p-2"
              accept="image/*"
              onChange={(e) => handleImageChange(e, setImageUrl, setImage)}
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white h-12 py-2 px-4 rounded hover:bg-blue-600 "
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      {/* Table for displaying items */}
      <h2 className="text-lg font-semibold mt-4">Added Items</h2>
      <div className="w-full max-h-80 overflow-x-auto overflow-y-auto">
        <table className="min-w-full table-auto bg-white border border-gray-300 mt-0">
          <thead>
            <tr className="min-w-full bg-blue-900 text-white border border-gray-300 mt-0">
              <th className="py-2 px-4 text-left border">Item Code</th>
              <th className="py-2 px-4 text-left border">Item Name</th>
              <th className="py-2 px-4 text-left border">Category</th>
              <th className="py-2 px-4 text-left border">Rate</th>
              <th className="py-2 px-4 text-left border">CGST %</th>
              <th className="py-2 px-4 text-left border">SGST %</th>
              <th className="py-2 px-4 text-left border">Type</th>
              <th className="py-2 px-4 text-left border">Unit</th>
              <th className="py-2 px-4 text-left border">Status</th>
              {/* <th className="py-2 px-4 text-left border">Available</th>
              <th className="py-2 px-4 text-left border">Unavailable</th> */}
              <th className="py-2 px-4 text-left border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {items
              .slice()
              .reverse()
              .map((item, index) => (
                <tr
                  key={item._id}
                  className={`${
                    index % 2 === 0 ? "bg-blue-100" : "bg-blue-200"
                  }`}
                >
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="itemCode"
                        value={editedItem.itemCode}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-24"
                      />
                    ) : (
                      item.itemCode
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="itemName"
                        value={editedItem.itemName}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-32"
                      />
                    ) : (
                      item.itemName
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="itemCategory"
                        value={editedItem.itemCategory}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-32"
                      />
                    ) : (
                      item.itemCategory
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="itemRate"
                        value={`${editedItem.itemRate}`}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-20"
                      />
                    ) : (
                      item.itemRate
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="cgst"
                        value={editedItem.cgst}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-20"
                      />
                    ) : (
                      item.cgst
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="sgst"
                        value={editedItem.sgst}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-20"
                      />
                    ) : (
                      item.sgst
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="text"
                        name="type"
                        value={editedItem.type}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-full"
                      />
                    ) : (
                      item.type
                    )}
                  </td>
                  <td className="py-0 px-1 border">
                    {editId === item._id ? (
                      <input
                        type="number"
                        name="unit"
                        value={editedItem.unit}
                        onChange={handleInputChange}
                        className="border px-1 py-1 w-20"
                      />
                    ) : (
                      item.unit
                    )}
                  </td>
                  <td className="py-0 pl-4 border">
                    <button
                      type="number"
                      name="status"
                      className={`border px-1 py-1 ${
                        item.status === "available"
                          ? "bg-green-500"
                          : "bg-red-500"
                      } text-white font-bold w-32`}
                      onClick={() => handleStatusToggle(item._id)}
                    >
                      {item.status}
                    </button>
                  </td>

                  {/* <td className="py-2 px-4 border">
                    <input
                      type="checkbox"
                      name="available"
                      checked={item.available}
                      onChange={(e) =>
                        handleAvailabilityToggle(e, item._id, "available")
                      }
                      className="transform scale-150"
                    />
                  </td>
                  <td className="py-2 px-4 border">
                    <input
                      type="checkbox"
                      name="unavailable"
                      checked={item.unavailable}
                      onChange={(e) =>
                        handleAvailabilityToggle(e, item._id, "unavailable")
                      }
                      className="transform scale-150"
                    />
                  </td> */}

                  <td className="py-2 px-4 border no-wrap">
                    {editId === item._id ? (
                      <button
                        className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                        onClick={() => handleSave(item._id)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                        onClick={() => handleEdit(item._id)}
                      >
                        Edit
                      </button>
                    )}
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 ml-2"
                      onClick={() => handleDelete(item._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Modal for adding a new category */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Add New Category</h2>
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full mb-4"
              placeholder="Enter new category"
            />
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => {
                  handleCategorySubmit();
                }}
              >
                Save
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => setShowModal(false)} // Close modal without saving
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
